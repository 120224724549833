import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { getReport, createGraphSettings } from "./api/api";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import DailyEventTable from "./DailyEventTable";
import DailyMetrics from "./DailyMetrics";
import moment from "moment";
import MonthlyGraph from "./MonthlyGraph";

const useStyles = makeStyles({
  currentlySelected: {
    borderBottom: "1px solid black",
    paddingBottom: ".1rem !important",
  },
});

export default function TagActivity(props) {
  const { apiUrl, csmToken } = props;
  const [activeMeasure, setActiveMeasure] = useState("");
  const [activeSelection, setActiveSelection] = useState("Receiving");
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [
      {
        backgroundColor: [],
        barPercentage: 0.7,
        borderColor: "green",
        borderWidth: 2,
        data: [
          137,
          37,
          171,
          405,
          357,
          443,
          485,
          525,
          207,
          234,
          389,
          267,
          324,
          441,
          206,
          424,
          501,
          306,
          437,
          554,
          483,
        ],
        fillColor: "rgba(100,220,220,0.9)",
        label: activeMeasure,
      },
    ],
  });
  const [rows, setRows] = useState([]);
  const [srows, setSrows] = useState({
    pickEvents: { label: "Pick", recentScanCount: 0, recentScans: [] },
    recievingEvents: {
      label: "Receiving",
      recentScanCount: 0,
      recentScans: [],
    },
    shipEvents: { label: "Ship", recentScanCount: 0, recentScans: [] },
    stowEvents: { label: "Stow", recentScanCount: 0, recentScans: [] },
  });
  const classes = useStyles();
  const currentDate = moment().format("MM/DD");

  useEffect(() => {
    // Initial render of Daily Processing and Daily Trends
    getReport({ ...props }).then((results) => {
      const { recievingEvents, stowEvents, pickEvents, shipEvents } = results;

      // Set the initial active item to Receiving
      setActiveMeasure("Receiving");
      setActiveSelection(`Receiving - ${currentDate}`);
      setGraphData(createGraphSettings(recievingEvents));
      setRows(recievingEvents.recentScans);

      // Initial set up for Daily processing
      setSrows({
        recievingEvents,
        stowEvents,
        pickEvents,
        shipEvents,
      });
    });
  }, [currentDate, props]);

  return (
    <Grid container spacing={3}>
      {/* Daily Processing */}
      <Grid item xs={3}>
        <Grid item fullwidth="true">
          <h5>Daily Processing</h5>
          <DailyMetrics
            activeMeasure={activeMeasure}
            apiUrl={apiUrl}
            csmToken={csmToken}
            currentDate={currentDate}
            setActiveMeasure={setActiveMeasure}
            setActiveSelection={setActiveSelection}
            setGraphData={setGraphData}
            setRows={setRows}
            setSrows={setSrows}
            srows={srows}
          />
        </Grid>
      </Grid>

      {/* Daily Trends */}
      <Grid item xs={9}>
        <h5> Daily Trends</h5>
        <MonthlyGraph
          activeMeasure={activeMeasure}
          activeSelection={activeSelection}
          graphData={graphData}
          setActiveSelection={setActiveSelection}
          setGraphData={setGraphData}
          setRows={setRows}
        />
      </Grid>

      {/* Currently Selected */}
      <Grid className={classes.currentlySelected} item xs={12}>
        <Typography variant="subtitle1">{activeSelection}</Typography>
      </Grid>

      {/* Data Table */}
      <Grid item xs={12}>
        <DailyEventTable rows={rows} />
      </Grid>
    </Grid>
  );
}
