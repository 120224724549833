import moment from "moment";

const endDate = moment().add(1, "days").format("YYYYMMDD");
const startDate = moment().subtract(21, "days").format("YYYYMMDD");

const getExternalId = async (props, assetId) => {
  const { apiUrl, authToken } = props;
  const results = await fetch(`${apiUrl}assets/${assetId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "auth-token": authToken,
    },
  })
    .then((response) => response.json())
    .then((response) => response.asset);

  return results;
};

function transformRecentScans(recentScans, props) {
  const newAssetsArray = [];
  const assetHashMap = {};

  recentScans.forEach(async (item) => {
    const { assetId, tag, device = {}, timeOfLog } = item;

    // TODO: Remove this hack. This is to make it work for the time being.
    const externalId = assetHashMap[assetId]
      ? assetHashMap[assetId].externalId
      : await getExternalId(props, assetId).then((response) => {
          assetHashMap[assetId] = response.asset;
          return response.externalId ? response.externalId : "";
        });

    const partNumber = externalId.split("'");
    const nsnValue = partNumber[1] ? partNumber[1] : "";
    const partnoValue = partNumber[2] ? partNumber[2].substr(2, 12) : "";
    const serialnoValue = partNumber[4] ? partNumber[4].substr(1) : "";
    const organizationId = item.organizationId.substr(0, 17);
    const strLocation = partNumber[7] ? partNumber[7].length : "4";
    const descValue = partNumber[7]
      ? partNumber[7].substr(2, strLocation - 4)
      : "";
    const { batteryPercent = "" } = device;

    newAssetsArray.push({
      //stLocation:externalId,
      assetId,
      date: moment(timeOfLog, "YYYYMMDDHHmmSS").format("MM/DD"),
      stBattPct: batteryPercent,
      stDesc: descValue,
      stLocation: organizationId,
      stNSN: nsnValue,
      stPartNo: partnoValue,
      stSerialNo: serialnoValue,
      stTagID: tag,
      time: moment(timeOfLog, "YYYYMMDDHHmmSS").format("HH:mm:SS"),
    });
  });

  return newAssetsArray;
}

export function createGraphSettings(element) {
  const scansBrokenUpByDay = {};
  const graphSettings = {
    labels: [],
    datasets: [
      {
        backgroundColor: [],
        barPercentage: 0.7,
        borderColor: "green",
        borderWidth: 2,
        data: [],
        fillColor: "rgba(100,220,220,0.9",
        label: element.label,
        scansBrokenUpByDay,
      },
    ],
  };
  // Builds the initial object with default values
  for (let i = 0; i <= 21; i++) {
    scansBrokenUpByDay[moment().subtract(i, "days").format("MM/DD")] = [];
  }
  // Pushes the events into their respective keys
  element.recentScans.forEach((scan) => {
    const { date } = scan;

    // The initial If is to catch additions that were made later in the day.
    // The backend is registering them as happening in the future as its storing the
    // scans on Greenwhich mean time.
    if (date === moment().add(1, "days").format("MM/DD")) {
      scansBrokenUpByDay[
        moment(date, "MM/DD").subtract(1, "days").format("MM/DD")
      ].push(scan);
    } else if (scansBrokenUpByDay[date]) {
      scansBrokenUpByDay[date].push(scan);
    } else {
      scansBrokenUpByDay[date] = [scan];
    }
  });

  Object.keys(scansBrokenUpByDay)
    .reverse()
    .forEach((item) => {
      graphSettings.labels.push(item);
      graphSettings.datasets[0].data.push(scansBrokenUpByDay[item].length);
    });

  return graphSettings;
}

const getRecievingEvents = async (props) => {
  const { apiUrl, authToken } = props;
  // const body = {
  //   csmToken,
  //   solrQuery: {
  //     // TODO: Replace this with whatever event is suppose to be associated
  //     fq:["event:scan"],
  //     facet:{
  //       range:"time_of_log",
  //       "f.time_of_log.range.start":startDate,
  //       "f.time_of_log.range.end":endDate,
  //       "f.time_of_log.range.gap":"+1DAY"
  //     },
  //     TZ:"EST"
  //   }
  // }

  const results = await fetch(
    `${apiUrl}assetHistories/events/Associate?startDate=${startDate}&endDate${endDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": authToken,
        // "auth-token": "2984057d-229d-4b62-a17b-b5c3ce8303a4",
      },
      // body: JSON.stringify(body),
    }
  )
    .then((response) => response.json())
    .then((response) => {
      const { recentScanCount, recentScans } = response;
      return {
        label: "Receiving",
        recentScanCount,
        recentScans: transformRecentScans(recentScans, props),
      };
    });

  return results;
};

const getPickEvents = async (props) => {
  const { apiUrl, authToken } = props;

  const results = await fetch(
    `${apiUrl}assetHistories/events/Pick?startDate=${startDate}&endDate${endDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": authToken,
      },
    }
  )
    .then((response) => response.json())
    .then((response) => {
      const { recentScanCount, recentScans } = response;
      return {
        label: "Pick",
        recentScanCount,
        recentScans: transformRecentScans(recentScans, props),
      };
    });

  return results;
};

const getShipEvents = async (props) => {
  const { apiUrl, authToken } = props;

  const results = await fetch(
    `${apiUrl}assetHistories/events/Dissociate?startDate=${startDate}&endDate${endDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": authToken,
      },
    }
  )
    .then((response) => response.json())
    .then((response) => {
      const { recentScanCount, recentScans } = response;
      return {
        label: "Ship",
        recentScanCount,
        recentScans: transformRecentScans(recentScans, props),
      };
    });

  return results;
};

const getStowEvents = async (props) => {
  const { apiUrl, authToken } = props;

  const results = await fetch(
    `${apiUrl}assetHistories/events/Stow?startDate=${startDate}&endDate${endDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": authToken,
      },
    }
  )
    .then((response) => response.json())
    .then((response) => {
      const { recentScanCount, recentScans } = response;
      return {
        label: "Stow",
        recentScanCount,
        recentScans: transformRecentScans(recentScans, props),
      };
    });

  return results;
};

export const getReport = async (props) => {
  const recievingEvents = await getRecievingEvents(props);
  const stowEvents = await getStowEvents(props);
  const pickEvents = await getPickEvents(props);
  const shipEvents = await getShipEvents(props);

  const results = {
    recievingEvents,
    stowEvents,
    success: true,
    pickEvents,
    shipEvents,
  };

  // TODO: What we should be returning
  // return {recievingEventsCount,stowEventsCount,pickEventsCount,shipEventsCount}

  // TODO: But we are returning faked out data
  return results;
};
